import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes, filterOutDocsPublishedInTheFuture } from "../lib/helpers";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import ProjectPreviewGridMobile from "../components/project-preview-grid-mobile";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { useMediaQuery } from "react-responsive";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    projects: allSanityPortfolioProject(
      limit: 99
      sort: { fields: [publishedAt], order: DESC }
      filter: { publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          isMobile
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;
  const [domLoaded, setDomloaded] = React.useState(false);

  React.useEffect(() => {
    setDomloaded(true);
  }, []);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects).filter(filterOutDocsPublishedInTheFuture)
    : [];

  const isMobile = useMediaQuery({ query: "(max-width: 599px)" });
  const preparedNodesMobile = projectNodes.filter(node => node.isMobile);
  const preparedNodesDesktop = projectNodes.filter(node => !node.isMobile);

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout transparent>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        {!isMobile && domLoaded ? (
          <ProjectPreviewGrid title="Latest projects" nodes={preparedNodesDesktop} />
        ) : (
          <ProjectPreviewGridMobile title="Latest projects" nodes={preparedNodesMobile} />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;

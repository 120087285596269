import React from 'react'
import ProjectPreview from './project-preview'
import Slider from 'react-slick'

import styles from './project-preview-grid.module.css'

const ProjectPreviewGrid = props => {
  var total = props.nodes.length // get the number of slides
  var rand = Math.floor(Math.random() * total) // random number

  const settings = {
    fade: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 550,
    pauseOnHover: false,
    initialSlide: rand
  }

  return (
    <Slider {...settings} className={styles.slider}>
      {props.nodes &&
        props.nodes.map(node => <div key={node.id}>{<ProjectPreview {...node} />}</div>)}
    </Slider>
  )
}

ProjectPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectPreviewGrid
